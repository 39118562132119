import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from 'react-intl'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import Img from "gatsby-image"

class SpeakersPage extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      expanded: [],
    };
  }

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale
    const post = data.wordpressPage
    const title = "Speakers"
    const hasData = post.acf && post.acf.page_speaker__flexcontent_page;

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate className="bg-white">
          <MainContainer className="px-5 sm:px-0 py-3 text-gray-900">
            {hasData &&
              post.acf.page_speaker__flexcontent_page.map((layout, i) => {

                if (layout.__typename === `WordPressAcf_speaker_divider`) {
                  return (
                    this.layoutDivider(`speaker_divider-${i}`, layout)
                  )
                }
                if (layout.__typename === `WordPressAcf_speaker_row`) {
                  return (
                    this.layoutSpeakerRow(`speaker_row-${i}`, layout)
                  )
                }
                if (layout.__typename === `WordPressAcf_speaker_panel`) {
                  return (
                    this.layoutSpeakerPanel(`speaker_panel-${i}`, layout)
                  )
                }

                return null
              })}
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }

  layoutDivider(key, layout) {

    return (
      <section key={key} className="speaker-layout-divider mt-16 mb-8">
        <p className="tk-harfang-pro my-3">
          <span className="text-purple-900 font-bold text-mmd border-cyan-900 border-r-2 pr-2 mr-2">{layout.lead}</span>
          <span className="text-purple-900 font-bold text-mmd pr-1 mr-1">{layout.title}</span>
          <span className="text-cyan-900 font-light text-mmd">{layout.subtitle}</span>
        </p>
        <div className="border-solid border-t-2	border-cyan-900"></div>
      </section>
    )
  }

  layoutSpeakerRow(key, layout) {

    const isExpanded = this.state.expanded.indexOf(key) !== -1;

    return (
      <section key={key} className="speaker-layout-row my-10">
        <p className="tk-harfang-pro text-mmd font-bold text-purple-900 uppercase">{layout.title}</p>
        <div className="flex flex-wrap -mx-5">
          <div className="px-5 w-full sm:w-1/4">
            <Img className="w-100 mb-5 rounded" style={{ maxWidth: `240px` }} fluid={layout.image_src.localFile.childImageSharp.fluid} alt={layout.name} />
          </div>
          <div className="px-5 w-full sm:w-3/4">
            <p className="tk-harfang-pro text-mmd font-bold text-purple-900 mt-0 mb-1">{layout.name}</p>
            <p className="text-olg text-blue-900 mt-0 mb-1">{layout.description}</p>
            <div className={(isExpanded) ? '' : 'readmore'}>
              <div className="mt-5" dangerouslySetInnerHTML={{ __html: layout.about }}></div>
            </div>
            <div className="text-right">
              {!isExpanded &&
                <strong className="cursor-pointer text-osm text-blue-900" onClick={(event) => this.setState({ expanded: [...this.state.expanded, key] })}>
                  <FormattedMessage id="Read More" />...
                </strong>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }

  layoutSpeakerPanel(key, layout) {

    return (
      <section key={`speaker-layout-panel-${key}`} className="speaker-layout-panel my-10">
        <p className="tk-harfang-pro text-mmd font-bold text-purple-900 uppercase">{layout.title}</p>

        <div className="flex flex-wrap -mx-10">
          {layout.speakers &&
            layout.speakers.map((speaker, i) => {
              return (
                <div key={`speaker-layout-panel-speaker-${i}`} className="w-full sm:w-1/2 md:w-1/2 px-10 my-5">
                  <div className="">
                    <Img className="w-100 mb-5 rounded" style={{ maxWidth: `240px` }} fluid={speaker.image_src.localFile.childImageSharp.fluid} alt={speaker.name} />
                  </div>
                  <div className="">
                    <p className="tk-harfang-pro text-mmd font-bold text-purple-900 mt-0 mb-1">{speaker.name}</p>
                    <p className="text-olg text-blue-900 mt-0 mb-1">{speaker.description}</p>
                    <div className="mt-5" dangerouslySetInnerHTML={{ __html: speaker.about }}></div>
                  </div>
                </div>
              )
            })}
        </div>
      </section>
    )
  }
}

export default SpeakersPage

// ... on WordPressAcf_speaker_panel {
//   __typename
//   id
//   title
//   speakers {
//     about
//     description
//     image_src{
//       localFile {
//         childImageSharp {
//           fluid(maxWidth: 240) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//     }
//     name
//   }
// }

export const query = graphql`
  query($pId: Int!, $pType: String!) {
    wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      id
      title
      acf {
        page_speaker__flexcontent_page {
          ... on WordPressAcf_speaker_divider {
            __typename
            lead
            subtitle
            title
          }
          ... on WordPressAcf_speaker_row {
            __typename
            name
            title
            image_src{
              localFile {
                childImageSharp {
                  fluid(maxWidth: 240) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            description
            about
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "jesedu-global-icon-white.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
